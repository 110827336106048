/* index.css */

/* Importar las fuentes */
@import url("https://fonts.googleapis.com/css2?family=Inter:wght@400;700&family=Poppins:wght@500;700&display=swap");

/* Fuente para el texto del cuerpo */
body {
  font-family: "Inter", sans-serif;
  color: #333; /* Ajusta el color según tu diseño */
  background-color: #f8f9fa; /* Fondo del sitio */
  margin: 0;
  padding: 0;
}

/* Fuente para los encabezados */
h1,
h2,
h3,
h4,
h5,
h6 {
  font-family: "Poppins", sans-serif;
  color: #222; /* Ajusta el color según tu diseño */
}

/* Estilos adicionales para botones, etc. */
.btn-primary {
  background-color: #ff6347; /* Coral oscuro para mejor contraste */
  color: #fff;
  border-radius: 30px;
  border: none;
}

/**
 * botones
 */
.btn {
  border-radius: 30px !important;
}

.bg-custom {
  background-color: #ece9e5 !important;
}

.btn-primary {
  background-color: #5a29b3 !important;
  border-color: #fff !important;
}

.card {
  border-radius: 35px !important;
}

.section {
  background-color: #fff;
}

footer {
  position: relative;
  background-color: #ffffff;
  box-shadow: 0px 5px 10px rgba(0, 0, 0, 0.1);
}

/**
 * navbar
 */
.navbar-nav {
  flex-direction: row;
  justify-content: center;
  align-items: center;
  height: 100%;
}

.nav-link {
  color: #555;
  padding: 0.5rem 1rem;
  transition: all 0.3s ease;
  border-radius: 20px;
  margin: 0 1rem;
  background-color: transparent;
  border: none;
}

.nav-link:hover {
  background-color: rgba(0, 100, 0, 0.1);
  color: rgba(0, 200, 0, 0.8) !important;
}

.avatar {
  display: inline-flex;
  justify-content: center;
  align-items: center;
  width: 30px;
  height: 30px;
  border-radius: 50%;
}
