:root {
  --color-pastel-rosa: #f8bbd0;
  --color-pastel-azul: #bbdefb;
  --color-pastel-verde: #a5d6a7;
  --color-gris-oscuro: #4a4a4a;
  --color-fondo: #ffffff;
  --color-primary-light: var(
    --color-lila-pastel
  ); /* Se mantiene para el color rosa */
  --color-lila-pastel: #d1c4e9; /* Nuevo color lila pastel */
}

.text-pink-pastel {
  color: var(
    --color-lila-pastel
  ); /* Cambiamos el color rosa por el color pastel rosa */
}

.section-sobre-mi {
  max-width: 1100px; /* Limitar el ancho máximo del contenido */
  margin: 0 auto; /* Centrar horizontalmente el contenido */
  padding-left: 20px; /* Añadir un poco de padding para evitar que el texto quede muy pegado a los bordes */
  padding-right: 20px;
}

.perfil-image-custom {
  width: 300px; /* Ajusta el ancho de la imagen */
  height: auto; /* Mantén la proporción */
  border-radius: 25%;
  margin-bottom: 20px; /* Añade espacio en la parte inferior */
}

.text-primary-light {
  color: var(
    --color-lila-pastel
  ); /* Ahora aplicamos el lila pastel a los títulos */
}

.custom-button {
  background-color: var(--color-pastel-verde)!important;
  border-color: var(--color-pastel-verde)!important;
  font-weight: 700;
}

.custom-button-lila{
  background-color: var(--color-lila-pastel)!important;
  border-color: var(--color-lila-pastel)!important;
  font-weight: 700;
}

.custom-button:hover {
  background-color: #81c784;
  border-color: #81c784;
}
